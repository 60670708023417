import React, { Component } from 'react'
import IAppUser from '../commonInterfaces/IAppUser'

interface ISecuredPageExampleProps {
    loggedInUser?: IAppUser
}
class SecuredPageExample extends Component<ISecuredPageExampleProps> {
    public render() {
        let userName = 'Unknown user'
        if (this.props.loggedInUser) userName = this.props.loggedInUser.userName
        return (
            <div>
                Secured page
                <div>{userName}</div>
            </div>
        )
    }
}

export default SecuredPageExample
