import React from 'react'
import { Component } from 'react'
import { Redirect } from 'react-router'

import { IAppState } from '../../App'
import PageHeader, {
    IPageHeaderPropsState,
} from '../../components/common/PageHeader'
import VerifyAccount, {
    IVerifyAccountPropsState,
} from '../../components/authentication/VerifyAccount'
import { StringUtil } from '../../utils/StringUtil'
import ApiHelper from '../../utils/ApiHelper'
import UrlHelper from '../../utils/UrlHelper'
import PageFooter from '../../components/common/PageFooter'

//Empty interface is the same as its parent, but I have made the decision to include them for ease of reuse.
interface IVerifyAccountPageProps extends IAppState {}

interface IVerifyAccountPageState
    extends IVerifyAccountPropsState,
        IPageHeaderPropsState {}

class VerifyAccountPage extends Component<
    IVerifyAccountPageProps,
    IVerifyAccountPageState
> {
    public constructor(props: IVerifyAccountPageProps) {
        super(props)
        this.state = {}
    }

    public render() {
        if (this.props.loggedInUser) return <Redirect to="/dashboard" />

        const login = UrlHelper.getUrlParamByKey('login')
        const showAccountOptions = !StringUtil.isNullOrEmpty(login)
        return (
            <div>
                <PageHeader
                    propsState={this.state}
                    goToCreateAccount={() => {}}
                    goToLogout={() => {}}
                    goToProfile={() => {}}
                />
                <div className="wide-center-width">
                    <div className="common-display-border">
                        <VerifyAccount
                            propsState={{ ...this.state, showAccountOptions }}
                            toggleAccordionState={this.toggleAccordionState}
                            resendConfirmationEmail={
                                this.resendConfirmationEmail
                            }
                        />
                    </div>
                    <PageFooter/>
                </div>
            </div>
        )
    }

    private toggleAccordionState = (
        accordionIndexToggled?: number | string
    ) => {
        if (accordionIndexToggled != null) {
            const value = Number(accordionIndexToggled)
            if (this.state.activeAccordionIndexes == null) {
                this.setState({ activeAccordionIndexes: [value] })
            } else {
                let newArray = this.state.activeAccordionIndexes
                let indexOfValue = newArray.indexOf(value)
                if (indexOfValue >= 0) newArray.splice(indexOfValue, 1)
                else newArray.push(value)
                this.setState({ activeAccordionIndexes: newArray })
            }
        }
    }

    private resendConfirmationEmail = () => {
        this.setState(
            { isLoading: true, showEmailSent: false, showEmailError: false },
            () => {
                const login = UrlHelper.getUrlParamByKey('login')
                ApiHelper.get(
                    `/api/v1/users/resendcreationemail?login=${login}&clientDomain=${UrlHelper.getFullDomain(
                        window.location.href
                    )}`
                )
                    .then(() => {
                        this.setState({
                            isLoading: false,
                            showEmailSent: true,
                            showEmailError: false,
                        })
                    })
                    .catch(() => {
                        this.setState({
                            isLoading: false,
                            showEmailSent: false,
                            showEmailError: true,
                        })
                    })
            }
        )
    }
}

export default VerifyAccountPage
