/* eslint @typescript-eslint/no-explicit-any: 0 */  // --> OFF
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'

import { StringUtil } from './StringUtil'
import UrlHelper from './UrlHelper'

class ApiHelper {
    public static get Url(): string {
        const protocol = window.location.protocol

        const localhostApi = '//localhost:51580'

        let apiParam = UrlHelper.getUrlParamByKey('api')
        if (!StringUtil.isNullOrEmpty(apiParam)) {
            switch (apiParam) {
                case 'local':
                    return protocol + localhostApi
            }
        }

        let domainLc = window.location.hostname.toLowerCase()
        if (domainLc.indexOf('localhost') >= 0) return protocol + localhostApi

        return '' //TODO: This should be the live api address
    }

    public static authenticate(login?: string, password?: string) {
        return new Promise<string>((resolve, reject) => {
            axios
                .post(
                    ApiHelper.Url + '/oauth/token',
                    'grant_type=password&username=' +
                        login +
                        '&password=' +
                        password
                )
                .then((result: AxiosResponse<any>) => {
                    //We pass back the response so the consumer can decide what to do with it:
                    resolve(result.data.access_token as string)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    public static get(relativeUrl: string) {
        return new Promise<AxiosResponse<any>>((resolve, reject) => {
            //We might need options
            let axiosRequestConfig = ApiHelper.buildAxiosRequestConfig()

            axios
                .get(ApiHelper.Url + relativeUrl, axiosRequestConfig)
                .then(result => {
                    resolve(result)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    public static post(relativeUrl: string, data: any) {
        return new Promise<AxiosResponse<any>>(function(resolve, reject) {
            //We might need options
            let axiosRequestConfig = ApiHelper.buildAxiosRequestConfig()

            axios
                .post(ApiHelper.Url + relativeUrl, data, axiosRequestConfig)
                .then(result => {
                    resolve(result)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }

    public static buildAxiosRequestConfig() {
        let axiosRequestConfig: AxiosRequestConfig = {}

        //We store the token in sessionStorage. If available we will pass it along
        let authToken = sessionStorage.getItem('authToken')
        if (authToken) {
            axiosRequestConfig.headers = {
                Authorization: 'Bearer ' + authToken,
            }
        }

        return axiosRequestConfig
    }
}

export default ApiHelper
