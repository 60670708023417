export class StringUtil {
    public static isNullOrEmpty(value?: string): boolean {
        if (value && value.trim().length > 0) 
            return false
        return true
    }

    public static isEmail(email?: string): boolean {
        if (email) {
            let rgx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return rgx.test(String(email).toLowerCase())
        }
        return false
    }

    public static containsUpperCase(value?: string): boolean {
        if (value) 
            return /[A-Z]/.test(value)
        return false
    }

    public static containsLowerCase(value?: string): boolean {
        if (value) 
            return /[a-z]/.test(value)
        return false
    }

    public static containsNumber(value?: string): boolean {
        if (value) 
            return /[0-9]/.test(value)
        return false
    }

    public static containsSymbol(value?: string): boolean {
        if (value) 
            return /[~`!#$%\^&*+=\-\[\]\\';,/{}|":<>\?]/.test(value)
        return false
    }
}
