import React, { Component } from 'react'
import { Form, Divider, Button, Header, Label } from 'semantic-ui-react'
import { StringUtil } from '../../utils/StringUtil'

//This interface represents all of the state that this UI component can have. 
//However this component is not authoritative for state. 
//You will notice that this is passed in as props to this component. The view model is the Single Source Of Truth for this components state. 

//I use natively null states, so you will notice that all of these can be null. States are then additive. 
//It makes it incredibly easy to set up and consume, and forces you to think about how this will look
//while it is still loading. It also means your UI piece can be self contained and show its own errors as needed. 
export interface ICreateAccountPropsState {
    isCreateAccountLoading?: boolean
    serverError?: string
    showUnknownError?: boolean
    showLoginRequiredError?: boolean
    showLoginNotAvailableError?: boolean
    showLoginInvalidChars?: boolean
    showEmailRequiredError?: boolean
    showEmailBadError?: boolean
    showPasswordRequiredError?: boolean
    showPasswordMatchError?: boolean
    showPasswordComplexError?: boolean
    showFirstNameError?: boolean
    showLastNameError?: boolean
    password?: string
    confirmPassword?: string
    login?: string
    email?: string
    firstName?: string
    lastName?: string
}

//propsState is a common property to all of my UI components.
//It represents all of the UI states set at the ViewModel layer for this UI component. (See above interface). 
//Components don't have state themselves. Their ViewModels do. We pass state through the props to components.
//Everything else in my props are methods passed in from the ViewModel. The UI component has no methods of its 
//own with minor exceptions (eg: to rotate an image that is specific to this UI only)
export interface ICreateAccountProps {
    propsState: ICreateAccountPropsState
    performCreateAccount: () => void
    storeLogin: (login?: string) => void
    storeEmail: (email?: string) => void
    storePassword: (password?: string) => void
    storeConfirmPassword: (confirmPassword?: string) => void
    storeFirstName: (password?: string) => void
    storeLastName: (password?: string) => void
    validateLogin: () => void
    validateEmailAddress: () => void
    validatePassword: () => void
    validateConfirmPassword: () => void
    validateFirstName: () => void
    validateLastName: () => void
    goToLogin: () => void
}

//This is a class. You probably already knew that. But it is important to note that it should NOT be. 
//This component should really be a function component instead of a class component. Class components are
//more expensive for React to create. Since this component is stateless and logic-less it should be a function.
//MVVM lends to more efficient practices this way. 
class CreateAccount extends Component<ICreateAccountProps> {
    public render() {
        return (
            <div>
                <Header as="h1">Create an account</Header>
                <Form>
                    <Form.Input
                        data-cy="login"
                        label="Login"
                        type="text"
                        //Did you see this following line? This is bad: 
                        onChange={event =>
                            this.props.storeLogin(event.target.value)
                        }
                        //This is better: 
                        onBlur={this.validateLogin}
                    />
                    {this.props.propsState.showLoginRequiredError && (
                        <div className="error-closer-top">
                            <Label
                                basic
                                color="red"
                                pointing
                                data-cy="login-req-error">
                                A login is required
                            </Label>
                        </div>
                    )}
                    {this.props.propsState.showLoginNotAvailableError && (
                        <div className="error-closer-top">
                            <Label
                                basic
                                color="red"
                                pointing
                                data-cy="login-avail-error">
                                This login is not available.
                            </Label>
                        </div>
                    )}
                    {this.props.propsState.showLoginInvalidChars && (
                        <div className="error-closer-top">
                            <Label
                                basic
                                color="red"
                                pointing
                                data-cy="login-char-error">
                                This login has some invalid characters in it.
                                The login should not contain any spaces or
                                special characters.
                            </Label>
                        </div>
                    )}

                    <Form.Input
                        data-cy="email"
                        label="Email address"
                        type="text"
                        onChange={this.storeEmail}
                        onBlur={this.validateEmail}
                    />
                    {this.props.propsState.showEmailRequiredError && (
                        <div className="error-closer-top">
                            <Label
                                basic
                                color="red"
                                pointing
                                data-cy="email-req-error">
                                An email address is required
                            </Label>
                        </div>
                    )}
                    {this.props.propsState.showEmailBadError && (
                        <div className="error-closer-top">
                            <Label
                                basic
                                color="red"
                                pointing
                                data-cy="email-format-error">
                                An email address is required - this does not
                                appear to match an email address
                            </Label>
                        </div>
                    )}

                    <Form.Input
                        data-cy="first-name"
                        label="First name"
                        type="text"
                        onChange={this.storeFirstName}
                        onBlur={this.validateFirstName}
                    />
                    {this.props.propsState.showFirstNameError && (
                        <div className="error-closer-top">
                            <Label
                                basic
                                color="red"
                                pointing
                                data-cy="first-name-req-error">
                                A first name is required.
                            </Label>
                        </div>
                    )}
                    <Form.Input
                        data-cy="last-name"
                        label="Last name"
                        type="text"
                        onChange={this.storeLastName}
                        onBlur={this.validateLastName}
                    />
                    {this.props.propsState.showLastNameError && (
                        <div className="error-closer-top">
                            <Label
                                basic
                                color="red"
                                pointing
                                data-cy="last-name-req-error">
                                A last name is required.
                            </Label>
                        </div>
                    )}

                    <Form.Input
                        data-cy="password"
                        label="Password"
                        type="password"
                        onChange={this.storePassword}
                        onBlur={this.validatePassword}
                    />
                    {this.props.propsState.showPasswordRequiredError && (
                        <div className="error-closer-top">
                            <Label
                                basic
                                color="red"
                                pointing
                                data-cy="pass-required-error">
                                A password is required
                            </Label>
                        </div>
                    )}
                    {this.props.propsState.showPasswordComplexError && (
                        <div className="error-closer-top">
                            <Label
                                basic
                                color="red"
                                pointing
                                data-cy="pass-complexity-error">
                                Your password needs to be at least 6 characters
                                long, and contain at least one uppercase,
                                lowercase, and a number or symbol.
                            </Label>
                        </div>
                    )}

                    <Form.Input
                        data-cy="password-confirm"
                        label="Confirm Password"
                        type="password"
                        onChange={this.storeConfirmPassword}
                        onBlur={this.validateConfirmPassword}
                    />
                    {this.props.propsState.showPasswordMatchError && (
                        <div className="error-closer-top">
                            <Label
                                basic
                                color="red"
                                pointing
                                data-cy="pass-match-error">
                                The passwords do not match
                            </Label>
                        </div>
                    )}

                    <Button
                        data-cy="submit"
                        content="Create"
                        primary
                        fluid
                        loading={this.props.propsState.isCreateAccountLoading}
                        disabled={this.props.propsState.isCreateAccountLoading}
                        onClick={this.performCreateAccount}
                    />
                    {this.props.propsState.showUnknownError && (
                        <div>
                            <Label
                                basic
                                color="red"
                                pointing
                                data-cy="unknown-error">
                                {!StringUtil.isNullOrEmpty(
                                    this.props.propsState.serverError
                                )
                                    ? this.props.propsState.serverError
                                    : 'An unknown error has occurred'}
                            </Label>
                        </div>
                    )}
                </Form>
                <Divider horizontal>Already have an account?</Divider>
                <Button
                    data-cy="go-to-login"
                    content="Go to the login page"
                    secondary
                    onClick={this.goToLogin}
                />
            </div>
        )
    }

    //Creating an inline lambda (see "This is bad" above) creates a new function for every render call.
    //Moving these into a lambda at the class level makes one per class, and then we reuse the existing ones every render call
    //The lambdas avoid the need to bind. 

    //The UI component does not make decisions on how to store data. The ViewModel does.
    private storeEmail = (event: React.ChangeEvent<HTMLInputElement>) => this.props.storeEmail(event.target.value)
    private storeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => this.props.storeFirstName(event.target.value)
    private storeLastName = (event: React.ChangeEvent<HTMLInputElement>) => this.props.storeLastName(event.target.value)
    private storePassword = (event: React.ChangeEvent<HTMLInputElement>) =>  this.props.storePassword(event.target.value)
    private storeConfirmPassword = (event: React.ChangeEvent<HTMLInputElement>) => this.props.storeConfirmPassword(event.target.value)

    private validateLogin = () => this.props.validateLogin()    
    private validateEmail = () => this.props.validateEmailAddress()    
    private validateFirstName = () => this.props.validateFirstName()    
    private validateLastName = () => this.props.validateLastName()    
    private validatePassword = () => this.props.validatePassword()    
    private validateConfirmPassword = () => this.props.validateConfirmPassword()

    private performCreateAccount = () => this.props.performCreateAccount()
    private goToLogin = () => this.props.goToLogin()             
}

export default CreateAccount
