import React, { Component } from 'react'
import {
    Icon,
    Button,
    Header,
    Accordion,
    AccordionTitleProps,
    Label,
    List,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'

export interface IVerifyAccountProps {
    propsState: IVerifyAccountPropsState

    //state altering methods:
    toggleAccordionState: (index?: string | number) => void
    resendConfirmationEmail: () => void
}

export interface IVerifyAccountPropsState {
    isLoading?: boolean
    showAccountOptions?: boolean
    showEmailSent?: boolean
    showEmailError?: boolean
    activeAccordionIndexes?: number[]
}

class VerifyAccount extends Component<IVerifyAccountProps> {
    public render() {
        let { activeAccordionIndexes } = this.props.propsState
        if (activeAccordionIndexes == null) activeAccordionIndexes = []

        //TODO: Review these cases near release:
        return (
            <div>
                <Header as="h1">Verify your email address</Header>
                {!this.props.propsState.showAccountOptions && (
                    <div>
                        <Header size="small" data-cy="missing-params-header">
                            We&semi;re not sure why your here
                        </Header>
                        <div className="common-space-below">
                            Normally you would be redirected to this page
                            because your email address needs to be verified,
                            however we cannot find any account information for
                            you.
                        </div>
                        <div>
                            We assume that there was an unstable balance in the
                            universe that caused you to see this error. Here are
                            some things that you can try:
                        </div>
                        <List bulleted>
                            <List.Item>
                                Try going to the dashboard and see if it prompts
                                you to log in: <Link to="/">Dashboard</Link>
                            </List.Item>
                            <List.Item>
                                If you continue to see this message, please
                                contact support at support@alertnetworking.com
                            </List.Item>
                        </List>
                    </div>
                )}
                {this.props.propsState.showAccountOptions && (
                    <div>
                        <Header size="small" data-cy="good-params-header">
                            One last step
                        </Header>
                        <div className="common-space-below">
                            Now that you have created your account we just need
                            to verify your email address. Please check your
                            email inbox for a message from us with a link.
                        </div>
                        <div className="common-space-below">
                            Don&semi;t forget to check any junk folders that you
                            may have. If you still cannot find it, you can click
                            the button below to resend it.
                        </div>
                        <div className="common-space-below">
                            By verifying your email account, you agree to use
                            our service in accordance to our terms and
                            conditions. Some important highlights are included
                            on this page. The full disclosure can be found{' '}
                            <Link to="/terms-and-conditions">here</Link>.
                        </div>
                        <Button
                            primary
                            loading={this.props.propsState.isLoading}
                            disabled={this.props.propsState.isLoading}
                            onClick={this.resendEmail}>
                            Resend the verification email
                        </Button>

                        {this.props.propsState.showEmailSent && (
                            <Label pointing="left">
                                Another email has been sent
                            </Label>
                        )}
                        {this.props.propsState.showEmailError && (
                            <Label pointing="left">
                                An unknown error occurred when we attempted to
                                send another email for this account
                            </Label>
                        )}

                        <Header size="small">
                            Terms and Conditions highlights
                        </Header>
                        <Accordion fluid styled>
                            <Accordion.Title
                                active={activeAccordionIndexes.indexOf(0) >= 0}
                                index={0}
                                onClick={this.handleAccordionClick}>
                                <div>
                                    <Icon name="dropdown" />
                                    <em>
                                        The tools on this site are intended for
                                        informational use
                                    </em>
                                </div>
                            </Accordion.Title>
                            <Accordion.Content
                                active={activeAccordionIndexes.indexOf(0) >= 0}>
                                <div className="common-space-below">
                                    <p>
                                        While we believe that this is a great
                                        tool for aiding you in planning and
                                        reporting your network infrastructure,
                                        we cannot account for everything that an
                                        individual network may entail. Therefore
                                        it is intended for information use and
                                        reporting only. Alert Networking cannot
                                        be held liable for any problems,
                                        discrepancies, or other faults within
                                        any real-world network; whether based in
                                        whole or part from any tool on the site.
                                    </p>
                                    <p>
                                        In addition, we do everything that we
                                        reasonably can to protect the
                                        information that you provide to us, but
                                        we cannot protect you from a physical
                                        copy of a report left on a desk, a
                                        disgruntled employee who still has
                                        access to the site, or any number of
                                        other scenarios. Therefore, we are not
                                        liable for the misuse of your networking
                                        infrastructure information, regardless
                                        of how it may have been obtained. We
                                        assume you to have the necessary
                                        precautions to protect your network from
                                        unauthorized use, and that you are
                                        managing who can view your networking
                                        information. Highly sensitive networks
                                        should not be modeled on this tool.
                                    </p>
                                </div>
                            </Accordion.Content>

                            <Accordion.Title
                                active={activeAccordionIndexes.indexOf(1) >= 0}
                                index={1}
                                onClick={this.handleAccordionClick}>
                                <Icon name="dropdown" />
                                <em>We protect your personal information</em>
                            </Accordion.Title>
                            <Accordion.Content
                                active={activeAccordionIndexes.indexOf(1) >= 0}>
                                <div className="common-space-below">
                                    <p>
                                        Your privacy is held in the highest
                                        regards with us. We collect your name,
                                        login, email, and the information to
                                        build the network reports you see on
                                        this site. However, we do not share this
                                        information with anyone that we do not
                                        have to. We will never sell your
                                        information. We are using some third
                                        party tools in which we have to provide
                                        your email address, name, or login,
                                        however we will only use these tools
                                        internally to our business. Your
                                        networking information is never shared.
                                    </p>
                                </div>
                            </Accordion.Content>

                            <Accordion.Title
                                active={activeAccordionIndexes.indexOf(2) >= 0}
                                index={2}
                                onClick={this.handleAccordionClick}>
                                <Icon name="dropdown" />
                                <em>
                                    We keep your cost low by having limited
                                    staff
                                </em>
                            </Accordion.Title>
                            <Accordion.Content
                                active={activeAccordionIndexes.indexOf(2) >= 0}>
                                <div className="common-space-below">
                                    <p>
                                        You may notice that we provide a year of
                                        reports and maps for about the same cost
                                        as you could have one report created by
                                        a real human. We do this by keeping our
                                        employee count low, and our expenses in
                                        the black. This has some advantages and
                                        disadvantages. It means that the cost
                                        for you to use the service remains low,
                                        and our ability to scale-up is more
                                        flexible. However, it also means that we
                                        may take longer to respond to your
                                        support requests. We have tried to
                                        create an intuitive experience, so
                                        hopefully you will not need us, but if
                                        you do, please be aware that we will get
                                        to you as soon as we can. It may also
                                        mean that our product evolves slower
                                        than a larger companies product would,
                                        however we strive to have a very stable
                                        platform. We also do not guarantee a
                                        specific uptime. We strive to be up and
                                        running 99.9999% of the time, but we do
                                        not place an official guarantee on it.
                                    </p>
                                </div>
                            </Accordion.Content>

                            <Accordion.Title
                                active={activeAccordionIndexes.indexOf(3) >= 0}
                                index={3}
                                onClick={this.handleAccordionClick}>
                                <Icon name="dropdown" />
                                <em>
                                    We bill a year at time on a recurring basis
                                </em>
                            </Accordion.Title>
                            <Accordion.Content
                                active={activeAccordionIndexes.indexOf(3) >= 0}>
                                <div className="common-space-below">
                                    <p>
                                        With an account you have free features
                                        that you can test and experiment with.
                                        You can also sign up for billed services
                                        that will allow you to access additional
                                        features of the site. These services are
                                        provided to you for a year from the time
                                        of billing. Payment is set up on a
                                        recurring cycle, automatically billing
                                        you for the same services at the
                                        beginning of the following year. You can
                                        cancel your next billing cycle at
                                        anytime, however it is important to note
                                        that this does not affect your services
                                        that you have until the end of your
                                        billed year.
                                    </p>
                                </div>
                            </Accordion.Content>

                            <Accordion.Title
                                active={activeAccordionIndexes.indexOf(4) >= 0}
                                index={4}
                                onClick={this.handleAccordionClick}>
                                <Icon name="dropdown" />
                                <em>Our refund policy</em>
                            </Accordion.Title>
                            <Accordion.Content
                                active={activeAccordionIndexes.indexOf(4) >= 0}>
                                <div className="common-space-below">
                                    <p>
                                        Generally we do not offer refunds. We do
                                        this to prevent abuse of the system. For
                                        about the same cost to have a report
                                        generated by a real person, you can sign
                                        up for our service and generate as many
                                        as you want on our site for a full year.
                                        Should someone sign up, create reports,
                                        and then cancel and refund their
                                        service, we would be providing them
                                        these professional reports at almost no
                                        cost. We would not be able to maintain
                                        our product. If you have extenuating
                                        circumstances you can contact our
                                        support group and we will evaluate each
                                        case individually.
                                    </p>
                                    <p>
                                        You can cancel your billing agreement
                                        with us at any time and we will not bill
                                        you once your year expires. You will
                                        have access to the services until that
                                        time ends.
                                    </p>
                                </div>
                            </Accordion.Content>
                        </Accordion>

                        <Header size="small">
                            Helpful information for this page
                        </Header>
                        <Accordion fluid styled>
                            <Accordion.Title
                                active={activeAccordionIndexes.indexOf(5) >= 0}
                                index={5}
                                onClick={this.handleAccordionClick}>
                                <Icon name="dropdown" />
                                <em>What if I mistyped my email address?</em>
                            </Accordion.Title>
                            <Accordion.Content
                                active={activeAccordionIndexes.indexOf(5) >= 0}>
                                <div className="common-space-below">
                                    <p>
                                        If you have mistyped your email address
                                        on account creation then you will have
                                        no way to confirm your account. To solve
                                        this, you will need to create another
                                        account with a different login, but with
                                        the correct email address.
                                    </p>
                                    <p>
                                        Once you have verified an account with
                                        your email address and you are able to
                                        log in, you can merge the two accounts
                                        together and select which login you
                                        would like to keep.
                                    </p>
                                </div>
                            </Accordion.Content>
                        </Accordion>
                    </div>
                )}
            </div>
        )
    }

    //Methods to extract information in the control so that I am not writing things like this:
    //onClick={()=>{}} //Because that creates a new method for every render. This does not:
    private handleAccordionClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        data: AccordionTitleProps
    ) => {
        const { index } = data
        this.props.toggleAccordionState(index)
    }

    private resendEmail = () => {
        this.props.resendConfirmationEmail()
    }
}

export default VerifyAccount
