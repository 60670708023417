//imports are broken into external/internal
import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import axios from 'axios'

import ConfirmAccount, {
    IConfirmAccountPropsState,
} from '../../components/authentication/ConfirmAccount'
import PageHeader, {
    IPageHeaderPropsState,
} from '../../components/common/PageHeader'
import Api from '../../utils/ApiHelper'
import { IAppState } from '../../App'
import UrlHelper from '../../utils/UrlHelper'
import { StringUtil } from '../../utils/StringUtil'
import PageFooter from '../../components/common/PageFooter'

interface IConfirmAccountPageProps extends IAppState {}

interface IConfirmAccountPageState
    extends IConfirmAccountPropsState,
        IPageHeaderPropsState {}

class ConfirmAccountPage extends Component<
    IConfirmAccountPageProps,
    IConfirmAccountPageState
> {
    public constructor(props: IConfirmAccountPageProps) {
        super(props)
        this.state = {}
    }

    public componentDidMount() {
        const userId = UrlHelper.getUrlParamByKey('userId')
        const verificationCode = UrlHelper.getUrlParamByKey('verificationCode')

        if (
            StringUtil.isNullOrEmpty(userId) ||
            StringUtil.isNullOrEmpty(verificationCode)
        ) {
            this.setState({
                isConfirmAccountLoading: false,
                showConfirmedAccount: false,
                showConfirmedAccountError: false,
                showMissingInfo: true,
            })
        } else {
            this.setState(
                {
                    isConfirmAccountLoading: true,
                    showConfirmedAccount: false,
                    showConfirmedAccountError: false,
                    showMissingInfo: false,
                },
                () => {
                    axios
                        .get(
                            Api.Url +
                                `/api/v1/users/confirmEmail?userId=${userId}&verificationCode=${verificationCode}`
                        )
                        .then(() => {
                            this.setState({
                                isConfirmAccountLoading: false,
                                showConfirmedAccount: true,
                                showConfirmedAccountError: false,
                                showMissingInfo: false,
                            })
                        })
                        .catch(() => {
                            this.setState({
                                isConfirmAccountLoading: false,
                                showConfirmedAccount: false,
                                showConfirmedAccountError: true,
                                showMissingInfo: false,
                            })
                        })
                }
            )
        }
    }

    public render() {
        if (this.props.loggedInUser != null) return <Redirect to="/dashboard" />

        return (
            <div>
                <PageHeader
                    propsState={this.state}
                    goToCreateAccount={() => {}}
                    goToLogout={() => {}}
                    goToProfile={() => {}}
                />
                <div className="wide-center-width">
                    <div className="common-display-border">
                        <ConfirmAccount propsState={this.state} />
                    </div>
                    <PageFooter/>
                </div>
            </div>
        )
    }
}

export default ConfirmAccountPage
