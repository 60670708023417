import { StringUtil } from './StringUtil'

class SecurityHelper {
    public static checkIfLoginContainsReservedChars(login?: string) {
        if (
            login &&
            (login.indexOf('[') >= 0 ||
                login.indexOf(']') >= 0 ||
                login.indexOf(' ') >= 0)
        )
            return true //Reserved for automation and admin logins
        return false
    }

    public static checkPasswordComplexityIsOk(password?: string) {
        if (
            password &&
            password.length > 6 &&
            StringUtil.containsUpperCase(password) &&
            StringUtil.containsLowerCase(password) &&
            (StringUtil.containsNumber(password) ||
                StringUtil.containsSymbol(password))
        )
            return true
        return false
    }
}

export default SecurityHelper
