import React, { Component } from 'react'
import { Header, Loader, Label, List } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

export interface IConfirmAccountProps {
    propsState: IConfirmAccountPropsState
}

export interface IConfirmAccountPropsState {
    isConfirmAccountLoading?: boolean
    showConfirmedAccount?: boolean
    showConfirmedAccountError?: boolean
    showMissingInfo?: boolean
}

class ConfirmAccount extends Component<IConfirmAccountProps> {
    public render() {
        const showDefaultState =
            !this.props.propsState.isConfirmAccountLoading &&
            !this.props.propsState.showConfirmedAccount &&
            !this.props.propsState.showConfirmedAccountError &&
            !this.props.propsState.showMissingInfo

        return (
            <div>
                <Header as="h1">Confirm your email address</Header>
                {showDefaultState && <p>Loading...</p>}
                {this.props.propsState.showMissingInfo && (
                    <div data-cy="missing-info">
                        <p>
                            This is the page that you would normally arrive at
                            when you confirm your email account, however the
                            link appears to be incorrect and is missing
                            information.
                        </p>
                        <p>
                            Please verify that the link you followed is
                            complete.
                        </p>
                    </div>
                )}
                {this.props.propsState.isConfirmAccountLoading && (
                    <>
                        <p>
                            <Loader active inline={true} />
                        </p>
                        <p>Confirming your account...</p>
                    </>
                )}
                {this.props.propsState.showConfirmedAccount && (
                    <div data-cy="confirmed">
                        <p>
                            Congratulations! Your account was successfully
                            confirmed.
                        </p>
                        <Link
                            data-cy="go-to-login"
                            className="link-margin-top"
                            to="/login">
                            Continue to the login page
                        </Link>
                    </div>
                )}
                {this.props.propsState.showConfirmedAccountError && (
                    <>
                        <div
                            className="common-space-below"
                            data-cy="confirm-error">
                            <Label basic color="red">
                                An error has occurred
                            </Label>
                        </div>
                        <p>
                            We could not confirm an account using the link
                            provided. Here are a few things you can try:
                        </p>
                        <List bulleted>
                            <List.Item>
                                If you followed a link to get here, try copying
                                the link directly and paste it into your
                                browsers address bar.
                            </List.Item>
                            <List.Item>
                                If you copied and pasted the link, please verify
                                that you have the full link in the address bar.
                            </List.Item>
                            <List.Item>
                                Our servers should always be up and running, but
                                in the unlikely event that we are experiencing
                                an outage then you would see this message. Wait
                                for a while and try it again.
                            </List.Item>
                            <List.Item>
                                If everything else fails, please contact support
                                using the email address that you provided. If we
                                can communicate to you at that email address,
                                then we can clearly mark it as confirmed.
                            </List.Item>
                        </List>
                    </>
                )}
            </div>
        )
    }
}

export default ConfirmAccount
