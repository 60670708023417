import React, { Component } from 'react'
import TermsAndConditions from '../../components/legal/termsAndConditions'
import PageFooter from '../../components/common/PageFooter'


class TermsAndConditionsPage extends Component {
    public render() {
        return (<div>

            <TermsAndConditions />
            <PageFooter/>
        </div>)
    }
}

export default TermsAndConditionsPage
