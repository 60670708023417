import React, { Component } from 'react'
import { Form, Divider, Button, Header, Label } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

export interface ILoginProps {
    propsState: ILoginPropsState

    //data storage methods: makes it so you can swap out how you store data without affecting this control
    storePassword: (password?: string) => void
    storeLogin: (login?: string) => void

    //state altering methods: The logic page will look into data storage and alter state accordingly
    validateLogin: () => void
    validatePassword: () => void
    performLogin: () => void
    goToCreateAccount: () => void
}

export interface ILoginPropsState {
    isLoading?: boolean
    showBadLoginError?: boolean
    showUnknownError?: boolean
    showLoginRequiredError?: boolean
    showPasswordRequiredError?: boolean
    password?: string
    login?: string
}

//Components don't have state themselves. Their pages do. We pass state through the props to components.

class Login extends Component<ILoginProps> {
    public render() {
        return (
            <div>
                <Header as="h1">Login</Header>
                <Form>
                    <Form.Input
                        data-cy="login"
                        label="Login"
                        type="text"
                        onChange={event =>
                            this.props.storeLogin(event.target.value)
                        }
                        onBlur={() => this.props.validateLogin()}
                    />
                    {this.props.propsState.showLoginRequiredError && (
                        <div className="error-closer-top">
                            <Label
                                basic
                                color="red"
                                pointing
                                data-cy="login-error">
                                A login is required
                            </Label>
                        </div>
                    )}

                    <Form.Input
                        data-cy="password"
                        label="Password"
                        type="password"
                        onChange={event =>
                            this.props.storePassword(event.target.value)
                        }
                        onBlur={() => this.props.validatePassword()}
                    />
                    {this.props.propsState.showPasswordRequiredError && (
                        <div className="error-closer-top">
                            <Label
                                basic
                                color="red"
                                pointing
                                data-cy="password-error">
                                A password is required
                            </Label>
                        </div>
                    )}

                    <Button
                        data-cy="submit"
                        content="Login"
                        primary
                        fluid
                        loading={this.props.propsState.isLoading}
                        disabled={this.props.propsState.isLoading}
                        onClick={() => this.props.performLogin()}
                    />
                    {this.props.propsState.showBadLoginError && (
                        <div>
                            <Label
                                basic
                                color="red"
                                pointing
                                data-cy="bad-login-error">
                                We could not log you in with this user name and
                                password
                            </Label>
                        </div>
                    )}
                    {this.props.propsState.showUnknownError && (
                        <div>
                            <Label
                                basic
                                color="red"
                                pointing
                                data-cy="unknown-error">
                                An unknown error has occurred
                            </Label>
                        </div>
                    )}
                </Form>
                <Link
                    data-cy="go-to-pass-recover"
                    className="link-margin-top"
                    to="/passwordrecovery">
                    Forgot your password
                </Link>
                <Divider horizontal>Need a login?</Divider>
                <Button
                    data-cy="go-to-create"
                    content="Create a new account"
                    icon="add"
                    labelPosition="left"
                    secondary
                    onClick={() => this.props.goToCreateAccount()}
                />
            </div>
        )
    }
}

export default Login
