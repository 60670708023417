import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import { ProtectedRoute, ProtectedRouteProps } from './utils/ProtectedRoute'
import SecuredPageExample from './pages/SecuredPageExample'
import LoginPage from './pages/authentication/LoginPage'
import 'semantic-ui-css/semantic.min.css'
import './App.css'
import CreateAccountPage from './pages/authentication/CreateAccountPage'
import IAppUser from './commonInterfaces/IAppUser'
import VerifyAccountPage from './pages/authentication/VerifyAccountPage'
import TermsAndConditionsPage from './pages/legal/TermsAndConditionsPage'
import ConfirmAccountPage from './pages/authentication/ConfirmAccountPage'
import SessionHelper from './utils/SessionHelper'
import AlertNetworkingIsComingPage from './pages/wwwbasic/AlertNetworkingIsComingPage'

export interface IAppState {
    loggedInUser?: IAppUser
}

export interface IAppProps {}

class App extends Component<IAppProps, IAppState> {
    public constructor(props: IAppProps) {
        super(props)
        const loggedInUser = SessionHelper.getAuthenticatedUser()
        this.state = { loggedInUser: loggedInUser }
    }

    public render() {
        const protectedRouteProps: ProtectedRouteProps = {
            isAuthenticated: this.state.loggedInUser != null,
            authenticationPath: '/login',
        }

        return (
            <div>
                <Switch>
                    <Route exact path="/" component={AlertNetworkingIsComingPage} />
                    <Route
                        path="/login:referrer?"
                        render={() => (
                            <LoginPage
                                performLogin={(user: IAppUser) =>
                                    this.login(user)
                                }
                                loggedInUser={this.state.loggedInUser}
                            />
                        )}
                    />
                    <Route
                        path="/createaccount"
                        render={() => (
                            <CreateAccountPage
                                loggedInUser={this.state.loggedInUser}
                            />
                        )}
                    />
                    <Route
                        path="/terms-and-conditions"
                        render={() => <TermsAndConditionsPage />}
                    />
                    <Route
                        path="/verifyaccount:login?:referrer?"
                        render={() => (
                            <VerifyAccountPage
                                loggedInUser={this.state.loggedInUser}
                            />
                        )}
                    />
                    <Route
                        path="/confirmAccount:userId?:verificationCode?"
                        render={() => (
                            <ConfirmAccountPage
                                loggedInUser={this.state.loggedInUser}
                            />
                        )}
                    />
                    <ProtectedRoute
                        {...protectedRouteProps}
                        path="/dashboard"
                        render={() => (
                            <SecuredPageExample
                                loggedInUser={this.state.loggedInUser}
                            />
                        )}
                    />
                </Switch>
            </div>
        )
    }

    public login(user: IAppUser) {
        SessionHelper.setAuthenticatedUser(user)
        this.setState({ loggedInUser: user })
    }

    public logout() {
        //Remove the oauth token from memory:
        SessionHelper.clearAuthTokenAndUser()
        //Clear the logged in user:
        this.setState({ loggedInUser: undefined })
    }
}

export default App
