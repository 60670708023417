class UrlHelper {
    public static getUrlParamByKey(key: string) {
        let params = this.getUrlParams()
        return params.get(key)
    }

    public static getUrlParams() {
        let params = new Map<string, string>()
        if (window.location.search) {
            let searchParts = window.location.search.substr(1).split('&')
            for (let i = 0; i < searchParts.length; i++) {
                let kv = searchParts[i].split('=')
                if (kv.length >= 2) 
                    params.set(kv[0], kv[1])
                else if (kv.length == 1) 
                    params.set(kv[0], '')
            }
        }

        return params
    }

    //Most of these get used by Unit Testing to mock window.location
    public static getProtocol(fullUrl: string) {
        let indexOfColon = fullUrl.indexOf('://')
        if (indexOfColon >= 0) 
            return fullUrl.substring(0, indexOfColon + 1)
        return ''
    }

    public static getDomainName(fullUrl: string) {
        let protocolLessDomain = fullUrl

        let indexOfSlashes = fullUrl.indexOf('//')
        if (indexOfSlashes >= 0)
            protocolLessDomain = fullUrl.substring(indexOfSlashes + 2)

        let indexOfNextSlash = protocolLessDomain.indexOf('/')
        if (indexOfNextSlash >= 0)
            return protocolLessDomain.substring(0, indexOfNextSlash)
        return protocolLessDomain
    }

    public static getFullDomain(fullUrl: string) {
        const protocol = this.getProtocol(fullUrl)
        if (protocol)
            return (
                this.getProtocol(fullUrl) + '//' + this.getDomainName(fullUrl)
            )
        return this.getDomainName(fullUrl)
    }

    public static getPort(fullUrl: string) {
        let domain = this.getDomainName(fullUrl)

        let indexOfColon = domain.indexOf(':')
        if (indexOfColon >= 0) return domain.substring(indexOfColon + 1)
        return ''
    }

    public static getPathName(fullUrl: string) {
        let domain = this.getDomainName(fullUrl)

        let endOfDomain = fullUrl.indexOf(domain) + domain.length
        return fullUrl.substring(endOfDomain)
    }

    public static getSearch(fullUrl: string) {
        let indexOfQ = fullUrl.indexOf('?')
        if (indexOfQ >= 0) return fullUrl.substring(indexOfQ)
        return ''
    }

    public static getLocationObject(fullUrl: string) {
        return {
            href: fullUrl,
            hostname: this.getDomainName(fullUrl),
            pathname: this.getPathName(fullUrl),
            protocol: this.getProtocol(fullUrl),
            port: this.getPort(fullUrl),
            search: this.getSearch(fullUrl),
        }
    }
}

export default UrlHelper
