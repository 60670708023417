import React from 'react'
import { Component } from 'react'
import PageHeader, {IPageHeaderPropsState} from '../../components/common/PageHeader'
import AlertNetworkingIsComing from '../../components/wwwbasic/AlertNetworkingIsComing'
import PageFooter from '../../components/common/PageFooter'

interface IAlertNetworkingIsComingPageState extends IPageHeaderPropsState{

}

interface IAlertNetworkingIsComingPageProps {

}

class AlertNetworkingIsComingPage extends Component<IAlertNetworkingIsComingPageProps, IAlertNetworkingIsComingPageState>{
    public constructor(props: IAlertNetworkingIsComingPageProps) {
        super(props)
        this.state = {}
    }

    public render() {
        return (<div>
            <PageHeader propsState={this.state}/>
            <div className="wide-center-width">
                <div className="common-display-border common-space-below">
                    <AlertNetworkingIsComing />
                </div>
                <PageFooter />
            </div>
        </div>)
    }
}

export default AlertNetworkingIsComingPage