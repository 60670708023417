import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import Login, { ILoginPropsState } from '../../components/authentication/Login'
import PageHeader, {
    IPageHeaderPropsState,
} from '../../components/common/PageHeader'
import { StringUtil } from '../../utils/StringUtil'
import Api from '../../utils/ApiHelper'
import IAppUser from '../../commonInterfaces/IAppUser'
import { IAppState } from '../../App'
import UrlHelper from '../../utils/UrlHelper'
import SessionHelper from '../../utils/SessionHelper'
import PageFooter from '../../components/common/PageFooter'

interface ILoginPageProps extends IAppState {
    performLogin: (user: IAppUser) => void
}

//Because we are using interfaces I really don't need to define my own state... Just import it. The only thing I define is state used directly here.
interface ILoginPageState extends ILoginPropsState, IPageHeaderPropsState {
    redirectToCreateAccount?: boolean
    redirectToEmailVerification?: boolean
}

class LoginPage extends Component<ILoginPageProps, ILoginPageState> {
    public constructor(props: ILoginPageProps) {
        super(props)
        this.state = {}
    }

    public render() {
        if (this.props.loggedInUser != null) {
            //If you have a referrer, you need to go there first...
            let referrer = UrlHelper.getUrlParamByKey('referrer')
            if (referrer) return <Redirect to={referrer} />
            else return <Redirect to="/dashboard" />
        }

        if (this.state.redirectToEmailVerification) {
            if (this.state.login) {
                let referrer = UrlHelper.getUrlParamByKey('referrer')
                let fullReferrer = ''
                if (!StringUtil.isNullOrEmpty(referrer))
                    fullReferrer = '&referrer=' + referrer
                return (
                    <Redirect
                        to={
                            '/verifyaccount?login=' +
                            encodeURIComponent(this.state.login) +
                            fullReferrer
                        }
                    />
                )
            }
        }

        if (this.state.redirectToCreateAccount)
            return <Redirect to="/createaccount" />

        return (
            <div>
                <PageHeader
                    propsState={this.state}
                    goToCreateAccount={() => {
                        this.goToCreateAccount()
                    }}
                    goToLogout={() => {}}
                    goToProfile={() => {}}
                />
                <div className="standard-center-width">
                    <div className="common-display-border">
                        <Login
                            propsState={this.state}
                            storeLogin={(login?: string) => {
                                this.setState({ login: login })
                            }}
                            storePassword={(password?: string) => {
                                this.setState({ password: password })
                            }}
                            validateLogin={() => this.validateLogin()}
                            validatePassword={() => this.validatePassword()}
                            performLogin={() => this.performLogin()}
                            goToCreateAccount={() => {
                                this.goToCreateAccount()
                            }}
                        />
                    </div>
                    <PageFooter/>
                </div>
            </div>
        )
    }

    //These methods check stored values, and set state. I put the more complex methods near the top as I will be editing there more.
    private performLogin() {
        let loginLengthOk = !StringUtil.isNullOrEmpty(this.state.login)
        let passOk = !StringUtil.isNullOrEmpty(this.state.password)

        let allOk = loginLengthOk && passOk

        this.setState(
            {
                showLoginRequiredError: !loginLengthOk,
                showPasswordRequiredError: !passOk,
                isLoading: allOk,
                showBadLoginError: false,
                showUnknownError: false,
            },
            () => {
                if (allOk) {
                    //Go to the server and authenticate

                    Api.authenticate(this.state.login, this.state.password)
                        .then(authToken => {
                            //We will store the auth token in the API helper:
                            SessionHelper.setAuthToken(authToken)
                            //Get the user data

                            Api.get('/api/v1/users/self')
                                .then(response => {
                                    let user = response.data
                                    //Commit those credentials:
                                    this.props.performLogin(user)

                                    //Logging in sets state above us, so now we will re-render with a loggedInUser, redirecting to the calling page
                                })
                                .catch(() => {
                                    this.setState({
                                        isLoading: false,
                                        showUnknownError: true,
                                    })
                                })
                        })
                        .catch(error => {
                            if (
                                error.response &&
                                error.response.data &&
                                error.response.data.error === 'invalid_grant'
                            ) {
                                if (
                                    error.response.data.error_description ===
                                    'The user name or password is incorrect.'
                                )
                                    this.setState({
                                        isLoading: false,
                                        showBadLoginError: true,
                                    })
                                else if (
                                    error.response.data.error_description ===
                                    'User did not confirm email.'
                                ) {
                                    if (this.state.login)
                                        this.setState({
                                            isLoading: false,
                                            redirectToEmailVerification: true,
                                        })
                                    else
                                        this.setState({
                                            isLoading: false,
                                            showUnknownError: true,
                                        })
                                } else {
                                    this.setState({
                                        isLoading: false,
                                        showUnknownError: true,
                                    })
                                }
                            } else
                                this.setState({
                                    isLoading: false,
                                    showUnknownError: true,
                                })
                        })
                }
            }
        )
    }

    //I am using state to redirect in the render.
    //React Router has methods that wrap around history so I am using router to change history in case I need wrapped functionality from router later.
    private goToCreateAccount() {
        this.setState({ redirectToCreateAccount: true })
    }

    private validateLogin() {
        if (StringUtil.isNullOrEmpty(this.state.login))
            this.setState({ showLoginRequiredError: true })
        else this.setState({ showLoginRequiredError: false })
    }

    private validatePassword(password?: string) {
        if (!StringUtil.isNullOrEmpty(password))
            this.setState({ showPasswordRequiredError: true })
        else this.setState({ showPasswordRequiredError: false })
    }
}

export default LoginPage
