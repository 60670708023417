import React, { Component } from 'react'

class TermsAndConditions extends Component{
    public render(){
        return (<div>

        </div>)
    }
}

export default TermsAndConditions