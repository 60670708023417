//imports are broken into external/internal
import React from 'react'
import {Redirect} from 'react-router-dom'
import axios from 'axios'

import CreateAccount, {ICreateAccountPropsState} from '../../components/authentication/CreateAccount'
import PageHeader, {IPageHeaderPropsState} from '../../components/common/PageHeader'
import {StringUtil} from '../../utils/StringUtil'
import Api from '../../utils/ApiHelper'
import {IAppState} from '../../App'
import UrlHelper from '../../utils/UrlHelper'
import SecurityHelper from '../../utils/SecurityHelper'
import PageFooter from '../../components/common/PageFooter'

// Empty interfaces are usually frowned on, as an empty interface is the same as
// its parent However this makes it easier for another developer to duplicate
// good coding practices in the future
interface ICreateAccountPageProps extends IAppState {}

// Because we created interfaces in the UI component about how it should look, I
// really don't need to define my own state. Just extend the relevant
// interfaces. The only thing I define is state used directly here in this class.
interface ICreateAccountPageState
extends ICreateAccountPropsState,
IPageHeaderPropsState {
    redirectToLogin?: boolean 
    redirectToEmailConfirmPage?: boolean
}

// Since this is the view model, it controls state, and makes decisions on how to
// store data. So this component has real state. I differentiate the props used
// in UI vs the page by name. StateProps are passed into components, PageState
// combine those props into an authoritative state
class CreateAccountPage extends React.Component < ICreateAccountPageProps,
ICreateAccountPageState > {
    // Since we are guaranteed to have a render (and sometimes a constructor), why
    // not place them and other React methods first? Then it kinda reads top down.
    public constructor(props: ICreateAccountPageProps) {
        super(props)
        this.state = {}
    }

    public render() {
        if (this.state.redirectToLogin) 
            return <Redirect to="/login"/>

        if (this.state.redirectToEmailConfirmPage) 
            return <Redirect to={'/verifyaccount?login=' + this.state.login}/>

        if (this.props.loggedInUser != null) 
            return <Redirect to="/dashboard"/>

        return (
            <div>
                <PageHeader propsState={this.state}/>
                <div className="standard-center-width">
                    <div className="common-display-border common-space-below">
                        <CreateAccount //Because my state inherits from PropsState in the view, all I have to do to consume it in the UI is this one line:
                            propsState={this.state} storeLogin={this.storeLogin} storeEmail={this.storeEmail} storeFirstName={this.storeFirstName} storeLastName={this.storeLastName} storePassword={this.storePassword} storeConfirmPassword={this.storeConfirmPassword} //Methods that look into storage and manipulate state. Because storage is broken away from actions it inoculates the ui piece from change:
                            validateLogin={this.validateLogin} validateEmailAddress={this.validateEmailAddress} validatePassword={this.validatePassword} validateConfirmPassword={this.validateConfirmPassword} validateFirstName={this.validateFirstName} validateLastName={this.validateLastName} performCreateAccount={this.performCreateAccount} goToLogin={this.goToLogin}/>
                    </div>
                    <PageFooter/>
                </div>                
            </div>
        )
    }

    // These methods check stored values, and set state. I placed the more complex
    // methods near the top, as that is the more common place you will be working.
    // Any method that is doing work that is not affecting state gets moved into a
    // common util or helper class so it can be reused and unit tested. Here I am
    // also making an external call. I am not unit testing this as I am using E2E
    // testing to verify it, so it fits with the state manipulating calls.
    private performCreateAccount = () => {
        let showLoginRequiredError = StringUtil.isNullOrEmpty(this.state.login)
        let showLoginInvalidChars = SecurityHelper.checkIfLoginContainsReservedChars(this.state.login)
        let showEmailRequiredError = StringUtil.isNullOrEmpty(this.state.email)
        let showEmailBadError = this.state.email && !StringUtil.isEmail(this.state.email)
            ? true
            : false
        let showPasswordRequiredError = StringUtil.isNullOrEmpty(this.state.password)
        let showPasswordMatchError = this.state.password !== this.state.confirmPassword
        let showPasswordComplexError = !showPasswordRequiredError && !SecurityHelper.checkPasswordComplexityIsOk(this.state.password)
        let showFirstNameError = StringUtil.isNullOrEmpty(this.state.firstName)
        let showLastNameError = StringUtil.isNullOrEmpty(this.state.lastName)

        let allOk = !showLoginRequiredError && !showLoginInvalidChars && !showEmailRequiredError && !showEmailBadError && !showPasswordRequiredError && !showPasswordMatchError && !showPasswordComplexError && !showFirstNameError && !showLastNameError

        this.setState({
            isCreateAccountLoading: allOk,
            showLoginRequiredError: showLoginRequiredError,
            showLoginInvalidChars: showLoginInvalidChars,
            showEmailRequiredError: showEmailRequiredError,
            showEmailBadError: showEmailBadError,
            showPasswordRequiredError: showPasswordRequiredError,
            showPasswordMatchError: showPasswordMatchError,
            showPasswordComplexError: showPasswordComplexError,
            showFirstNameError: showFirstNameError,
            showLastNameError: showLastNameError
        }, () => {
            if (allOk) {
                //Go to the server and authenticate Axios is cleaner than the native call
                axios
                    .post(Api.Url + '/api/v1/users', {
                    Email: this.state.email,
                    UserName: this.state.login,
                    Password: this.state.password,
                    ConfirmPassword: this.state.confirmPassword,
                    FirstName: this.state.firstName,
                    LastName: this.state.lastName,
                    ClientDomain: UrlHelper.getFullDomain(window.location.href)
                })
                    .then(() => {
                        //redirect to email confirm
                        this.setState({isCreateAccountLoading: false, redirectToEmailConfirmPage: true})
                    })
                    .catch(error => {
                        let errorMessage = null
                        let showUsedUserName = false
                        if (error.response && error.response.data && error.response.data.modelState) {
                            if (error.response.data.modelState && error.response.data.modelState.error && error.response.data.modelState.error.length > 0 && error.response.data.modelState.error[0].indexOf('is already taken') >= 0) 
                                showUsedUserName = true
                            else if (error.response.data.modelState[''] && error.response.data.modelState[''].length > 0) {
                                errorMessage = error
                                    .response
                                    .data
                                    .modelState['']
                                    .join(' - ')
                            }
                        }
                        if (showUsedUserName) 
                            this.setState({isCreateAccountLoading: false, showUnknownError: false, showLoginNotAvailableError: true, serverError: errorMessage})
                        else 
                            this.setState({isCreateAccountLoading: false, showUnknownError: true, showLoginNotAvailableError: false, serverError: errorMessage})
                    })
            }
        })
    }

    // I am using state to redirect in the render. React Router has methods that wrap
    // around history so I am using router to change history in case I need wrapped
    // functionality from router later. Anything that should persist when I hit the
    // back button should still be stored in sessionStorage As React loses all state
    // when it navigates between pages.
    private goToLogin = () => {
        this.setState({redirectToLogin: true})
    }

    //Validate stored data and set state:
    private validateLogin = () => {
        if (StringUtil.isNullOrEmpty(this.state.login)) 
            this.setState({showLoginInvalidChars: false, showLoginNotAvailableError: false, showLoginRequiredError: true})
        else if (SecurityHelper.checkIfLoginContainsReservedChars(this.state.login)) 
            this.setState({showLoginInvalidChars: true, showLoginNotAvailableError: false, showLoginRequiredError: false})
        else 
            this.setState({showLoginInvalidChars: false, showLoginNotAvailableError: false, showLoginRequiredError: false})
    }

    private validateEmailAddress = () => {
        if (StringUtil.isNullOrEmpty(this.state.email)) 
            this.setState({showEmailBadError: false, showEmailRequiredError: true})
        else if (!StringUtil.isEmail(this.state.email)) 
            this.setState({showEmailBadError: true, showEmailRequiredError: false})
        else 
            this.setState({showEmailBadError: false, showEmailRequiredError: false})
    }

    private validatePassword = () => {
        if (StringUtil.isNullOrEmpty(this.state.password)) 
            this.setState({showPasswordRequiredError: true, showPasswordComplexError: false})
        else if (!SecurityHelper.checkPasswordComplexityIsOk(this.state.password)) 
            this.setState({showPasswordRequiredError: false, showPasswordComplexError: true})
        else {
            // In the rare case they edit the password after the confirm password, and now
            // they match, we will remove the error();
            if (this.state.password === this.state.confirmPassword) 
                this.setState({showPasswordRequiredError: false, showPasswordComplexError: false, showPasswordMatchError: false})
            else 
                this.setState({showPasswordRequiredError: false, showPasswordComplexError: false})
        }
    }

    private validateConfirmPassword = () => {
        if (this.state.password && this.state.confirmPassword !== this.state.password) 
            this.setState({showPasswordMatchError: true})
        else 
            this.setState({showPasswordMatchError: false})
    }

    private validateFirstName = () => {
        if (StringUtil.isNullOrEmpty(this.state.firstName)) 
            this.setState({showFirstNameError: true})
        else 
            this.setState({showFirstNameError: false})
    }

    private validateLastName = () => {
        if (StringUtil.isNullOrEmpty(this.state.lastName)) 
            this.setState({showLastNameError: true})
        else 
            this.setState({showLastNameError: false})
    }

    private storeLogin = (login?: string) => this.setState({login: login})
    private storeEmail = (email?: string) => this.setState({email: email})
    private storeFirstName = (firstName?: string) => {
        this.setState({firstName: firstName})
    }
    private storeLastName = (lastName?: string) => {
        this.setState({lastName: lastName})
    }
    private storePassword = (password?: string) => this.setState({password: password})
    private storeConfirmPassword = (confirmPassword?: string) => this.setState({confirmPassword: confirmPassword})
}

export default CreateAccountPage
