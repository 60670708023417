import IAppUser from '../commonInterfaces/IAppUser'

class SessionHelper {
    public static setAuthToken(authToken: string) {
        //Since auth token is exclusive to the api, I placed the session.setItem for it in the API Helper so we only have one class using the string "AuthToken"
        sessionStorage.setItem('authToken', authToken)
    }

    public static setAuthenticatedUser(appUser: IAppUser) {
        sessionStorage.setItem('authUser', JSON.stringify(appUser))
    }

    public static clearAuthTokenAndUser() {
        sessionStorage.removeItem('authToken')
        sessionStorage.removeItem('authUser')
    }

    public static getAuthenticatedUser() {
        let user = sessionStorage.getItem('authUser')
        if (user != null) 
            return JSON.parse(user)
        return null
    }
}

export default SessionHelper
