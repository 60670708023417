import React, { Component } from 'react'
import { Image, Icon, Dropdown, Header, Grid } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

export interface IHeaderProps {
    propsState: IPageHeaderPropsState
    goToCreateAccount?: () => void
    goToLogout?: () => void
    goToProfile?: () => void
}

export interface IPageHeaderPropsState {
    showLoginLink?: boolean
    showLoggedInOptions?: boolean
    userDisplayName?: string
    logoutLoading?: boolean
    allowLinkingToRoot?: boolean
}

class PageHeader extends Component<IHeaderProps> {
    public render() {
        const loggedInTrigger = this.props.propsState.userDisplayName ? (
            <span>
                <Icon name="user" inverted /> Hello,{' '}
                {this.props.propsState.userDisplayName}
            </span>
        ) : (
            <span>
                <Icon name="user" inverted /> Hello
            </span>
        )

        const loggedInOptions = [
            {
                key: 'user',
                text: this.props.propsState.userDisplayName ? (
                    <span>
                        Signed in as{' '}
                        <strong>{this.props.propsState.userDisplayName}</strong>
                    </span>
                ) : (
                    <span>You are signed in</span>
                ),
                disabled: true,
            },
            { key: 'profile', text: 'Your Profile', value: 'profile' },
            { key: 'sign-out', text: 'Sign Out', value: 'sign-out' },
        ]

        const headerContent = (
            <Header inverted size="huge" className="header-title-container">
                <Image
                    src={require('../../resources/images/connections.svg')}
                    size="tiny"
                    floated="left"
                    className="img-absolute"
                />
                Alert Networking
            </Header>
        )

        return (
            <div>
                <div className="header-main">
                    <Grid columns={2} padded>
                        <Grid.Row>
                            <Grid.Column>
                                {this.props.propsState.allowLinkingToRoot && (
                                    <Link to="/">{headerContent}</Link>
                                )}
                                {!this.props.propsState.allowLinkingToRoot && (
                                    <>{headerContent}</>
                                )}
                            </Grid.Column>
                            <Grid.Column>
                                <div className="header-login-container">
                                    {this.props.propsState.showLoginLink && (
                                        <Link to="/login">Login</Link>
                                    )}
                                    {this.props.propsState
                                        .showLoggedInOptions && (
                                        <Dropdown
                                            selectOnBlur={false}
                                            trigger={loggedInTrigger}
                                            options={loggedInOptions}
                                            direction="left"
                                            loading={
                                                this.props.propsState
                                                    .logoutLoading
                                            }
                                            onChange={(e, data) =>
                                                this.handleLoggedInOptionChange(
                                                    data.value
                                                )
                                            }
                                        />
                                    )}
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
                <div className="header-divider" />
            </div>
        )
    }

    private handleLoggedInOptionChange(dataValue: string | number | boolean | (string | number | boolean)[] | undefined) {
        switch (dataValue) {
            case 'profile':
                if(this.props.goToProfile)
                    this.props.goToProfile()
                break
            case 'sign-out':
                if(this.props.goToLogout)
                    this.props.goToLogout()
                break
        }
    }
}

export default PageHeader
