import React, { Component } from 'react'
import { Header } from 'semantic-ui-react'

class AlertNetworkingIsComing extends Component{
    public render(){
        return (<div>
                <Header as="h1">This is the future site of Alert Networking.</Header>
                <p>Welcome to the site! This is the future site of Alert Networking.</p>
                <p>We are surprised to see you. As you can see we are still setting up. We have not even told anyone we are here yet.</p>
                <p>We are still several months out, but please check back with us soon.</p>
            </div>)
    }
}

export default AlertNetworkingIsComing